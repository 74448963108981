import * as React from 'react';
import styledComponents from 'styled-components';
import { Layout } from '../components/layout';
import Banner from '../components/banners';
import Clinic from '../components/clinic';
import ClinicUnits from '../components/units';
import AlternativeClinicalBody from '../components/clinicalBody/alternativeVersion';

const TopContainer = styledComponents.div`
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 0;
`;
const meta = [
  <meta key='description' name='description' content={'Conheça a Clínica Dermatológica Angelucci com dados de nossos profissionais e fotos das nossas unidades.'} />,
];

const ClinicPage = () => (
  <Layout title={'Clínica Angelucci - A Clínica'} meta={meta}>
    <main>
      <TopContainer>
        <Banner name='clinica'><strong>A Clínica</strong></Banner>
        <Clinic />
        <ClinicUnits />
      </TopContainer>
      <AlternativeClinicalBody />
    </main>
  </Layout>
);

export default ClinicPage;
