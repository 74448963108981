import React from 'react';
import styledComponents from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import THEME from '../../themes';

const ClinicUnitsContainer = styledComponents.div`
  width: 100%;
  position: relative;
  font: normal normal bold 22px/26px PT Sans;
  color: ${THEME.COLORS.PRIMARY};
  text-transform: uppercase;
  margin-top: 30px;

  @media screen and (max-width: 650px){
    text-align: center;
  }

  z-index: 1;
`;

const InternalContainer = styledComponents.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1060px){
    margin: 0 20px;
    width: calc(100% - 40px);
  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: 650px){
    margin: 0 auto;
    width: calc(100% - 40px);
    max-width: 560px;

    .mobile {
      text-align: center;
      display: inline-block;
    }

    .desk {
      display: none;
    }
  }
`;

const Line = styledComponents.hr`
  border: 1px dashed #D0AD67;
  opacity: 1;
  width: 100%;
`;

const ClinicUnits = () => (
  <ClinicUnitsContainer>
    <InternalContainer>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <strong style={{ minWidth: 205 }}>unidade são paulo</strong>
        <Line />
      </div>
      <StaticImage className='desk' src='../../images/Fotos-SP-DeskTablet.png' alt="Imagens da unidade São Paulo"/>
      <StaticImage className='mobile' src='../../images/Fotos-SP-Mobile.png' alt="Imagens da unidade São Paulo"/>
      <div style={{
        display: 'flex', alignItems: 'center', marginBottom: 16, marginTop: 32,
      }}>
        <Line />
        <strong style={{ minWidth: 250, textAlign: 'right' }}>unidade s. b. do campo</strong>
      </div>
      <StaticImage className='desk' src='../../images/Fotos-SBC-DeskTablet.png' alt="Imagens da unidade de São Bernardo do Campo"/>
      <StaticImage className='mobile' src='../../images/Fotos-SBC-Mobile.png' alt="Imagens da unidade de São Bernardo do Campo"/>
    </InternalContainer>
  </ClinicUnitsContainer>
);

export default ClinicUnits;
