import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';
import { PrimaryLinkButtonWithoutGatsby } from '../button';
import { ContentDivisor } from '../divisor';
import { InstagemIcon, WhatsappIcon } from '../icons';
import { H2 } from '../titles';
import Circle from '../../images/Circulo-Prateado.png';
import { openWP } from '../modal/whatsappModal';
import KnowMore from '../modal/modalClinicalBody';

const ClinicalBodyContainer = styledComponents.div`
  background-color: rgb(243,243,243);
  width: 100%;
  background-image: url(${Circle});
  background-size: 360px 360px;
  background-repeat: no-repeat;
  background-position-y: calc(100% + 292px);
  background-position-x: calc(50% - 445px);
  padding-bottom: 80px;

  z-index: 0;
`;

const ContentContainer = styledComponents.div`
  margin: 0 auto;
  max-width: 1200px;
  text-align: right;
  z-index: 2;

  img {
    z-index: 1;
  }

  p {
    font: normal normal normal 16px/26px PT Sans;
    letter-spacing: 0.48px;
    line-height: 0.7;
    color: ${THEME.TEXT.BODY.COLOR};
  

    strong {
      font: normal normal bold 22px/26px PT Sans;
      letter-spacing: 0.66px;
      color: ${THEME.COLORS.PRIMARY};
    }
  }

  a.link {
    font: normal normal normal 16px/26px PT Sans;
    color: ${THEME.COLORS.PRIMARY};
    text-transform: uppercase;
    text-decoration: underline;
  }

  a {
    width: 250px;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child {
      text-align: left;
      min-width: 400px;
    }

    .gatsby-image-wrapper {
      max-width: 422px;
      min-width: 422px;
      margin: 0 40px;
    }

    @media screen and (max-width: 880px){ 
      &:first-child {
        margin-left: 15%;
      }

      &:last-child {
        margin-left: -15%;
      }
    }

  }

  @media screen and (max-width: 760px){ 

    & > div {
      flex-wrap: wrap;
      max-width: 70%;
      margin: 0 auto;
      
    }

    & > div:first-child {
      margin: 0 auto;
      min-width: 50px;
      margin-left: calc(50% - 250px);
    }

    & > div:last-child {
      margin: 0 auto;
      margin-top: 80px;
      min-width: 50px;
    }

    & > div:first-child {
      flex-direction: column-reverse;
    }

    .gatsby-image-wrapper {
      max-width: 422px;
      min-width: 100%;
      margin: 0 auto;

      img {
        min-width: 422px;
      }
    }
  }

  @media screen and (max-width: 340px){ 
    a {
      max-width: 200px;
    }
  }
`;

const FadedCircleContainer = styledComponents.div`
  position: absolute;
  top: 1850px;
  right: calc(50% - 750px);
  z-index: 0;
  width: 600px;

  img {
    width: 700px;
    height: 700px;
  }

  .gatsby-image-wrapper {
    overflow: visible;
  }
`;

const AlternativeClinicalBody = () => (
<ClinicalBodyContainer>
  <ContentDivisor />
  <H2>Corpo Clínico</H2>
  <ContentContainer>
    <div>
      <div>
        <p><strong>Dr. Renato Angelucci</strong></p>
        <p>Dermatologista | CRM 120766</p>
        <br/>
        <KnowMore
          title='Dr. Renato Angelucci'
          icon={<StaticImage src='../../images/foto-Renato@2x.png'/>}
          nid='renato'
          crm='CRM/SP 120766 '
          content={<>
              Dr. Renato Angelucci é Médico Dermatologista, especialista pela Sociedade
              Brasileira de Dermatologia (SBD), também com especialização em Cirurgia Dermatológica
              pela Sociedade Brasileira de Cirurgia Dermatológica (SBCD).
              <br /><br />
              Atualmente, é membro da
              Sociedade Brasileira de Dermatologia (SBD) e atua nas áreas da Dermatologia Clínica,
              Estética, Cirurgia Dermatológica e Tricologia.
            </>
          }/>
        <br/>
        <br/>
        <PrimaryLinkButtonWithoutGatsby href='https://www.instagram.com/drrenatoangeluccidermato/' target='_blank' rel="noreferrer" style={ { marginBottom: '10px' }}>
          <InstagemIcon color={ THEME.COLORS.SECONDARY } />Instagram Dr. Renato
        </PrimaryLinkButtonWithoutGatsby>
        <br />
        <PrimaryLinkButtonWithoutGatsby onClick={openWP}>
          <WhatsappIcon color={ THEME.COLORS.SECONDARY } />Marque uma consulta
        </PrimaryLinkButtonWithoutGatsby>
      </div>
      <StaticImage src='../../images/foto-Renato@2x.png'/>
    </div>
    <div>
      <StaticImage src='../../images/foto-Ana@2x.png'/>
      <div>
        <p><strong>Dra. Ana Carolina D. P. Angelucci</strong></p>
        <p>Dermatologista | CRM 135428</p>
        <br/>
        <KnowMore
          title='Dra. Ana Carolina D. P. Angelucci'
          icon={<StaticImage src='../../images/foto-Ana@2x.png'/>}
          nid='ana'
          crm='CRM/SP 135428'
          content={<>
              Dra. Ana Carolina Angelucci é Médica Dermatologista, com especialização e
              pós-graduação em Dermatologia pelo Centro de Ensino Superior de Valença/RJ,
              além de possuir especialização em Clínica Médica pela Irmandade da Santa
              Casa da Misericórdia de Santos/SP.
              <br /><br />
              Atua nas áreas de Dermatologia Clínica, Cirúrgica e Estética.
            </>
          }/>
        <br/>
        <br/>
        <PrimaryLinkButtonWithoutGatsby onClick={openWP}>
          <WhatsappIcon color={ THEME.COLORS.SECONDARY } />Marque uma consulta
        </PrimaryLinkButtonWithoutGatsby>
      </div>
    </div>
  </ContentContainer>
  <FadedCircleContainer>
    <StaticImage src='../../images/Circulo-Prateado-Desfocado.png' />
  </FadedCircleContainer>
</ClinicalBodyContainer>
);

export default AlternativeClinicalBody;
