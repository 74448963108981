import React from 'react';
import styledComponents from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { ContentDivisor } from '../divisor';
import { H2 } from '../titles';
import THEME from '../../themes';

const ClinicContainer = styledComponents.div`
  max-width: 1050px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  flex-wrap: no-wrap;
  overflow: visible;

  font: normal normal normal 16px/26px PT Sans;
  color: ${THEME.TEXT.BODY.COLOR};

  .lead {
    font: normal normal normal 22px/26px PT Sans;
    color: ${THEME.COLORS.PRIMARY};
  }

  @media screen and (max-width: 650px){
    justify-content: center;
    padding-right: 24px;

    p {
      max-width: 95%;
      text-align: justify;
    }

    p.lead {
      text-align: left;
    }
  }

  @media screen and (max-width: 530px){
    p {
      max-width: 65%;
      margin: 0 auto 24px;
    }
  }

  @media screen and (max-width: 400px){
    p {
      max-width: 50%;
    }
  }
`;

const ImgContainer = styledComponents.div`
  min-width: 478px;
  position: relative;
  overflow: visible;
  right: -75px;
  top: -40px;
  left: auto;

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 850px){
    right: -200px;
    top: 0px;
    margin-left: -130px;
  }

  @media screen and (max-width: 650px){
    right: 0;
    top: 0px;
    margin: 0 auto 64px;

    &.desk {
      display: none;
    }

    &.mobile {
      display: block;
    }

    p {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

`;

const Clinic = () => (
  <>
    <ContentDivisor />
    <H2>A Clínica</H2>
    <ClinicContainer>
      <div style={{ maxWidth: 550, paddingLeft: 24 }}>
        <p className='lead'>
          TODA A ESTRUTURA E QUALIDADE PARA O ATENDIMENTO CLÍNICO, CIRÚRGICO E ESTÉTICO.
        </p>
        <p>
          A <strong>CLÍNICA DERMATOLÓGICA ANGELUCCI</strong> oferece aos seus pacientes toda
          a estrutura e qualidade para o atendimento clínico, cirúrgico e estético, além da
          excelência nos tratamentos das doenças da pele, cabelos e unhas. Estamos instalados
          em duas unidades: <strong>SÃO PAULO/SP</strong> (Jardins) e
          <strong> SÃO BERNARDO DO CAMPO/SP</strong> (Centro).
        </p><br />

        <ImgContainer className="mobile">
          <StaticImage src='../../images/imagem-clinica.png' alt='A clínica'/>
        </ImgContainer>

        <p className='lead'>
          PELE: O MAIOR ÓRGÃO DO CORPO HUMANO.
        </p>
        <p>
          A pele é o maior órgão do corpo humano e também responsável por exercer funções
          de suma importância para a manutenção vital, como: revestimento, proteção,
          controle de troca de fluidos corporais, além de realizar regulação térmica e sensitiva.
        </p>
        <p>
          O <strong>MÉDICO DERMATOLOGISTA</strong> é o profissional apto e especializado para
          manter o seu cuidado, oferecendo suporte e seguimento de crianças, adultos e idosos.
        </p>
      </div>
      <ImgContainer className="desk">
        <StaticImage src='../../images/imagem-clinica.png' alt='A clínica'/>
      </ImgContainer>
    </ClinicContainer>
  </>
);

export default Clinic;
