/* eslint-disable react/prop-types */
import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';
import { blockScroll, changeVisibility, unblockScroll } from '../../utilities/windowManagement';

const Overlay = styledComponents.div`
  position: fixed;
  background: #ECECEC 0% 0% no-repeat padding-box;
  opacity: 0.78;

  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: none;
  overflow-y: hidden;
`;

const Overlay2 = styledComponents.div`
  position: fixed;
  opacity: 1;

  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: none;
  overflow: hidden scroll;
`;

const ModalContainer = styledComponents.div`
  position: relative;
  width: 60%;
  max-width: 1000px;
  height: auto;
  min-height: 50%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  opacity: 1;
  z-index: 110;
  top: 360px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 400px;
  overflow: visible;
  padding: 20px;

  .gatsby-image-wrapper {
    position: absolute;
    top: -200px;
    left: calc(50% - 250px)
  }

  p {
    font: normal normal normal 16px/26px PT Sans;
    letter-spacing: 0.48px;
    color: #6D6D6D;
    text-align: center;
    line-height: 1.5 !important;
  }

  p.lead {
    margin-top: 170px;
    font: normal normal bold 22px/26px PT Sans;
    letter-spacing: 0.66px;
    color: #D0AD67;
  }

  @media screen and (max-width: 1000px){
    width: 90%;
  }

`;

const CloseButton = styledComponents.a`
  position: absolute !important;
  top: 10px;
  right: 5px;
  left: auto;
  display: block;
  text-align: center;
  font: normal normal bold 22px/21px PT Sans;
  color: ${THEME.COLORS.PRIMARY};
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  border-radius: 15px;
  border: 1px solid ${THEME.COLORS.PRIMARY};
  opacity: 1;
  line-height: 30px;
  cursor: pointer;
  z-index: 10;

  @media screen and (max-width: 544px){
    top: -215px;
    max-width: 30px !important;
  }
`;

const onOpen = (id) => {
  changeVisibility(`#ov1${id}`);
  changeVisibility(`#ov2${id}`);
  blockScroll();
};

const onClose = (id) => {
  changeVisibility(`#ov1${id}`);
  changeVisibility(`#ov2${id}`);
  unblockScroll();
};

const KnowMore = ({
  icon, title, content, nid, crm,
}) => (
  <>
    <a className='link' onClick={() => onOpen(nid)} style={{ cursor: 'pointer', fontWeight: 'bold' }}>clique aqui e saiba mais!</a>
    <Overlay id={`ov1${nid}`} onClick={() => onClose(nid)}/>
    <Overlay2 id={`ov2${nid}`} >
      <ModalContainer>
        <CloseButton onClick={() => onClose(nid)}>X</CloseButton>
        {icon}
        <br />
        <div>
          <p className='lead'>{title}</p>
          <p className='crm'>{crm}</p>
          <p>
            {content}
          </p>
        </div>
      </ModalContainer>
    </Overlay2>
  </>
);

export default KnowMore;
